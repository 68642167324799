import { cn } from '@ahm/common-helpers';
import Image from 'next/image';
import type { HTMLProps, ReactNode, SVGProps } from 'react';

interface ResultBlockProps extends Omit<HTMLProps<HTMLDivElement>, 'title'> {
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  iconUrl?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  requestId?: string;
  children?: ReactNode;
  innerClassName?: string;
}

export function ResultBlock({
  icon: Icon,
  iconUrl,
  title,
  description,
  requestId,
  children,
  className,
  innerClassName,
  ...props
}: ResultBlockProps) {
  return (
    <div className={cn('container px-6', className)} {...props}>
      <div
        className={cn(
          'mx-auto flex min-h-[50dvh] max-w-[400px] flex-col items-center justify-center space-y-2 py-12 text-center',
          innerClassName
        )}
      >
        {Icon ? (
          <div className="relative mx-auto mb-2 h-[120px] w-auto">
            <Icon className="size-full" height={120} width={240} />
          </div>
        ) : null}
        {iconUrl ? (
          <div className="relative mx-auto mb-2 h-[120px] w-full">
            <Image
              alt="404"
              fill
              style={{ objectFit: 'contain' }}
              src={iconUrl}
              priority
            />
          </div>
        ) : null}
        {title ? (
          <h2 className="text-base font-semibold text-neutral-90 dark:text-neutral-10">
            {title}
          </h2>
        ) : null}
        {description ? (
          <p className="text-sm font-medium text-neutral-70 dark:text-neutral-30">
            {description}
          </p>
        ) : null}
        {requestId ? (
          <p className="text-xs font-semibold text-neutral-70 dark:text-neutral-30">
            Request ID:
            <code className="font-mono font-normal">{requestId}</code>
          </p>
        ) : null}
        {children}
      </div>
    </div>
  );
}
